import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import TextLoop from "react-text-loop"
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet"

import circle from "../img/circle.svg"
import circleOpaque from "../img/circle-opaque.svg"
import arrowRight from "../img/arrow-right.svg"
import SimpleOnboardingSection from "../components/SimpleOnboardingSection"
import ThreeBlurbsSection from "../components/ThreeBlurbsSection"
import Layout from "../components/Layout"
import ClientsSection from "../components/ClientsSection"
import HashtagHidden from "../components/HashtagHidden"

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  headings,
  subheading,
  clients,
  intro,
  closing,
}) => {
  return (
    <div>
      <div className="section is-small index-page">
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Kamsa - Compensation Management Software</title>
            <meta name="og:title" content="Kamsa - Compensation Management Software" />
            <meta name="description" content="Kamsa is a compensation management program providing real-time global salary data and expert support to make compensation review and benchmarking painless." />
          </Helmet>
          <div className="columns hero-columns">

            <div className="column is-half relative is-hidden-mobile">
              <Parallax translateY={['200px', '0px']}>
                <img className="hero-opaque" src={circleOpaque} alt="Orange circle" />
              </Parallax>

              <Parallax translateY={['-60px', '20px']}>
                <img className="hero-kamsa" src={image.publicURL} alt="Website Graphic" />
              </Parallax>

              <Parallax translateY={[20, -20]}>
                <img className="hero-circle" src={circle} alt="Circle" />
              </Parallax>
            </div>
              
            <div className='column relative hero-text'>
              <div className="block">
                <h1 className="title is-h1">
                  <TextLoop noWrap={false} interval={2800}>
                      {headings.map((heading, index) => <ReactMarkdown key={index}>{heading.text}</ReactMarkdown>)}
                  </TextLoop>
                </h1>
              </div>

              <div className="block">
                <h3 className="subtitle">{ subheading }</h3>
              </div>

              <div className="block">
                <div className="buttons group-radius">
                  <a className="button is-ivy " href="/kamsa">
                    How Kamsa Works
                    <div className="icon ml-3">
                      <img src={arrowRight} alt="Navigate" />
                    </div>
                  </a>

                  <a className="button is-ivy" href="/request-demo">
                    Book a Demo
                    <div className="icon ml-3">
                      <img src={arrowRight} alt="Navigate" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section section--gradient is-medium">
        <div className="container">
          <div className="columns">
            <ThreeBlurbsSection
             header={intro.heading}
             description={intro.description}
             buttonLink={intro.buttonLink}
             buttonText={intro.buttonText}
             blurbs={intro.blurbs}
             textColumnSize={""}
             playIcon
            />
          </div>
        </div>
      </section>

      <section id="clients" className="section section--gray is-medium no-padding-bottom">
        <ClientsSection clients={clients} />
      </section>

      <section className="section section--gradient is-medium no-padding-bottom">
        <SimpleOnboardingSection
            title={closing.title}
            text={closing.text}
            buttonLink={closing.buttonLink}
            buttonText={closing.buttonText}
            icons={closing.icons}
        />
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  headings: PropTypes.array,
  subheading: PropTypes.string,
  clients: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  closing: PropTypes.shape({
    icons: PropTypes.array,
  }),
}

const IndexPage = ({ data, pageContext }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <div className="hero-container">
      <Layout>
        <IndexPageTemplate
          image={frontmatter.image}
          title={frontmatter.title}
          heading={frontmatter.heading}
          headings={frontmatter.headings}
          subheading={frontmatter.subheading}
          clients={frontmatter.clients}
          description={frontmatter.description}
          intro={frontmatter.intro}
          closing={frontmatter.closing}
        />
        <HashtagHidden hashtags={frontmatter.landingPageHashtags} />
      </Layout>
    </div>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          publicURL
        }
        heading
        headings {
          text
        }
        subheading
        clients {
          title
          client {
            title
            image {
              publicURL
            }
          }
        }
        description
        intro {
          heading
          description
          buttonLink
          buttonText
          blurbs {
            image {
              publicURL
            }
            title
            text
          }
        }
        closing {
          title
          text
          buttonLink
          buttonText
          icons {
            title
            description
            image {
              publicURL
            }
          }
        }
        landingPageHashtags {
          title
        }
      }
    }
  }
`;
