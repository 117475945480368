import React from 'react'
import arrow from "../img/arrow.svg"
import CTAButton from "./CTAButton"
import PropTypes from "prop-types"

const SimpleOnboardingSection = (props) => {
    const {
        title,
        text,
        buttonLink,
        buttonText,
        icons,
    } = props
    return (
        <>
            <div className="container">
              <div className="columns is-centered">

                <div className="column is-6 has-text-centered">
                  <div className="block">
                    <h2 className="title is-2 has-text-weight-bold">{ title }</h2>
                  </div>
                  <div className="block">
                    <h3 className="subtitle">{ text }</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="section px-0">
              <div className="columns columns--closing">
                { icons.map((icon, index) =>
                  <>
                    <div key={index} className="closing--arrow is-hidden-touch is-narrow">
                      <img src={arrow} alt="Arrow"/>
                    </div>
                    <div className="column column--closing has-text-centered">
                      <div className="block">
                        <img className="blurb-image" src={icon.image.publicURL || ''} alt={icon.title} />
                      </div>
                      <div className="block has-text-centered">
                        <h3 className="subtitle">{ icon.description }</h3>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="section no-padding-top has-text-centered">
                <CTAButton link={buttonLink} text={buttonText} />
            </div>
        </>
    )
}

SimpleOnboardingSection.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })),
}

export default SimpleOnboardingSection